import React from 'react';
import './SectionTitle.scss';

const SectionTitleUp = ({subTitle, title, description}) => {
    return (
        <div className='section-title'>
            <span className='span'>{subTitle}</span>
            <h1>{title}</h1>
            <div  className="description"  dangerouslySetInnerHTML={{ __html: description }} style={{ fontSize: '16px', textAlign:"justify" }}  />
            <style jsx>{`
  .description ul {
      list-style-type: disc !important; /* Use 'disc' for a solid circle, or specify your own icon */
      padding-left: 20px; /* Adjust padding to make space for the icon */
    }

    .description li {
      margin-bottom: 10px; /* Adjust the margin as needed */
    }
`}</style>
        </div>
    );
};

export default SectionTitleUp;