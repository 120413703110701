import React from 'react';
import './Appointment.scss';
import SectionTitleUp from '../../components/SectionTitle/SectionTitleUp';
import { AiFillHome } from "react-icons/ai";
import ContactForm from '../../components/ContactForm/ContactForm';

const Appointment = () => {

    const mapLink = 'https://maps.google.com/maps?q=19,+Sunethradevi+Road,+Kohuwala,+Colombo,+Sri+Lanka&output=embed'

    return (
        <section className='appointment-section pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="google-map">
                            <iframe title='map' src={mapLink}></iframe>

                            <div className="location-name">
                                <AiFillHome />
                                <p>19, Colombo-Horana Road (Sunethradevi Road), Kohuwala, Nugegoda, Colombo, Sri Lanka</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="appointment-form-area">
                            <SectionTitleUp
                                subTitle="BOOK APPOINTMENT"
                                title="Contact 6thLane Dentistry"
                                description="For inquiries or appointments, please leave your contact details. Our care coordinator will reach out to you soon.."/>

                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Appointment;