import React from 'react';
import './Testimonial.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import imgOne from '../../assets/testimonial/1.png';
import imgTwo from '../../assets/testimonial/2.png';
import imgThree from '../../assets/testimonial/3.png';
import TestimoniCard from '../../components/TestimoniCard/TestimoniCard';
import { AiFillStar } from "react-icons/ai";
import Slider from "react-slick";

const Testimonial = () => {

    const testimonails = [
        {
            'img': imgOne,
            'name': 'Mrs. Ghandy - India',
            'description': 'I have been a patient of Dr Hiran for over 15 years and Dr Hiran is a very good and dedicated person taking the utmost care. I wish him all success in the future !',
            'ratings': [<AiFillStar />, <AiFillStar />, <AiFillStar />, <AiFillStar />, <AiFillStar />, <AiFillStar />]
        },
        {
            'img': imgTwo,
            'name': 'Mrs. Shehana Fausz - Sri Lanka',
            'description': 'I have been a patient of Dr Hiran Thenuwara over a decade now, and I can confidently say that my experience has been exceptional. His friendly demeanor and clear communication style make every appointment a comfortable and stress- free experience.He takes the time to thoroughly explain each step, ensuring im well aware in my dental care decisions. The quality of care I receive is consistently top - notch!',
            'ratings': [<AiFillStar />, <AiFillStar />, <AiFillStar />, <AiFillStar />, <AiFillStar />, <AiFillStar />]
        }
    
    ]

// slider
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

return (
    <section className='testimonail-section section-bg section-common pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <SectionTitle
                        subTitle="TESTIMONIALS"
                        title="Feedback from our patients"
                    />
                </div>
                <div className="col-lg-6">
                    <p className="pt-5">Elevating the oral health of thousands of families has surely created millions of red carpet smiles !</p>
                </div>
            </div>

            <Slider {...settings} className="testimoni-slider">
                {
                    testimonails.map(testimonail => <TestimoniCard testimonail={testimonail} />)
                }
            </Slider>

        </div>
    </section>
);
};

export default Testimonial;