import iconOne from '../../assets/features/1.png';
import iconTwo from '../../assets/features/2.png';
import iconThree from '../../assets/features/3.png';
import iconFour from '../../assets/features/4.png';
import iconFive from '../../assets/features/5.png';
import iconSix from '../../assets/features/6.png';
import iconSevven from '../../assets/features/7.png';
import iconEight from '../../assets/features/8.png';



const featuresData = [
    {
        'icon': iconOne,
        'title': 'Team, tech & material',
        'description': 'Meet our skilled, qualified, accredited, experienced, and friendly team, equipped with high-class materials, state-of-the-art technology, and cutting-edge facilities'
    },
    {
        'icon': iconTwo,
        'title': 'Standard protocols',
        'description': 'Adhering to internationally accepted sterility and infection control protocols'
    },
    {
        'icon': iconThree,
        'title': 'Affordability',
        'description': 'Competitive, transparent pricing with flexible payment options'
    },
    {
        'icon': iconFour,
        'title': 'Easy access',
        'description': 'Prompt appointments with minimal waiting times. Offering night and weekend appointments'
    },
    {
        'icon': iconFive,
        'title': 'Luxury amenities, a homely vibe, and a patient-centric journey',
        'description': ''
    },
    {
        'icon': iconSix,
        'title': 'Conveniently located in Colombo with private parking',
        'description': ''
    },
    {
        'icon': iconSevven,
        'title': 'Free wifi & Netflix',
        'description': ''
    },
    {
        'icon': iconEight,
        'title': 'Featuring a mini lounge and beverage station.',
        'description': ''
    },
]

export default featuresData;