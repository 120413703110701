import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import logo from './../assets/banner-popup.png';

const BannerPop = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (showModal) {
      const modalElement = document.getElementById('welcomeModal');
      if (modalElement) {
        const bsModal = new Modal(modalElement);
        bsModal.show();
      }
    }
  }, [showModal]);

  return (
    <>
      <div
        className="modal fade"
        id="welcomeModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content position-relative p-0 border-0 bg-transparent">
            {/* Close Button */}
            <button
              type="button"
              className="btn-close position-absolute"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                top: '10px',
                right: '10px',
                zIndex: 1055,
                backgroundColor: 'white',
                borderRadius: '50%',
              }}
            ></button>

            {/* Full Width Centered Image */}
            <img
              src={logo}
              alt="Welcome Banner"
              className="img-fluid rounded"
              style={{
                display: 'block',
                margin: '0 auto',
                width: '100%',
                height: 'auto',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerPop;
