import React from 'react';
import './Safety.scss';
import SectionTitleDown from '../../components/SectionTitle/SectionTitleDown';

const Safety = () => {
    return (
        <section className='safty-section pt-100 section-bg section-common pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <SectionTitleDown    
                    subTitle="QUALITY COMFORT TECHNOLOGY"
                    title="The reasons to choose our office"
                    description="Expert clinicians with high quality material and standard safety protocols.Relaxing experience in a warm friendly environment with a feel home-like vibe.Latest technology & tools for a modern approach."
                />

                <div className="safety-video ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/f4jG8BCl5s0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
            </div>
        </section>
    );
};

export default Safety;