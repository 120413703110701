import React from 'react';
import logo from '../../assets/footer_logo.png';
import './Footer.scss';
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from 'react-router-dom';
import call from '../../assets/footer/phone.png';
import time from '../../assets/footer/3.png';
import location from '../../assets/footer/2.png';

const Footer = () => {

    const currentYear = new Date().getFullYear();

    const footerMenu = [
        {
            'name': 'About Us',
            'link': '/about'
        },
        {
            'name': 'Services',
            'link': '/singleservice'
        },

        {
            'name': 'Blog',
            'link': '/blogs'
        },

    ];

    const footerContacts = [
        {
            'title': 'Phone Number',
            'info': '+0094 777 612 834',
            'icon': call
        }
    ]

    return (
        <footer className='pt-100 pb-70'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-5">
                        <div className="footer-logo">
                            <img src={logo} alt="logo" />
                        </div>
                        <p>6th Lane Dentistry in Kohuwala: Top-rated center for cosmetic, preventative, and restorative dentistry, orthodontics, maxillofacial surgery, and health & wellness.</p>

                        <div className="social-logo">
                            <p>Follow us on</p>
                            <ul>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/6thlanedentistry"><FaFacebookF /></a></li>
                                <li><a href="/"><FaTwitter /></a></li>
                                <li><a href="/"><FaInstagram /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2">
                        <div className="footer-link">
                            <p>Quick Links</p>
                            <ul>
                                {
                                    footerMenu.map(singleMenu => <li><Link to={singleMenu.link}>{singleMenu.name}</Link></li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="footer-contact">
                            <p>Contact & Information</p>

                            {
                                footerContacts.map(footerContact => {
                                    return <div className="contact-list">
                                        <div className="contact-icon">
                                            <img src={footerContact.icon} alt="call" />
                                        </div>
                                        <div className="contact-text">
                                            <p>{footerContact.title}</p>
                                            <a href="tel:0094777612834"><h5>{footerContact.info}</h5></a>
                                        </div>
                                    </div>
                                })
                            }

                            <div className="contact-list">
                                <div className="contact-icon">
                                    <img src={location} alt="location" />
                                </div>
                                <div className="contact-text">
                                    <p>Clinic Address</p>
                                    <h5>19, Colombo-Horana Road (Sunethradevi Road), Kohuwala, <br/>Nugegoda, Colombo, Sri Lanka</h5>
                                </div>
                            </div>

                            <div className="contact-list">
                                <div className="contact-icon">
                                    <img src={time} alt="call" />
                                </div>
                                <div className="contact-text">
                                    <p>Open Hour</p>
                                    <h5>Monday to Saturday <br/>
                                        4:00 PM - 8:00 PM</h5><br/>
                                        <h5>Sunday<br/>
                                        10:00 AM - 6:00 PM</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className='copy-text'>
                    <p>&copy; {currentYear} / 6thLane Dentistry / All Rights Reserved.</p>
                    </div>

                </div>
            </div>
        </footer>
    );
};

export default Footer;