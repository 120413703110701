import React from 'react';
import './Banner.scss';
import { Link } from 'react-router-dom';
import icon from '../../assets/banner/icons/Calling.png';
import bannerImg from '../../assets/banner/1.png';
import bannerPattern from '../../assets/banner/pattern_02.png';
import shapeOne from '../../assets/banner/vector_01.png';
import shapeTwo from '../../assets/banner/vector_02.png';
import shapeThree from '../../assets/banner/vector_03.png';
import shapeFour from '../../assets/banner/pattern.png';

const Banner = () => {

    return (
        <section className='section-bg section-common banner-section'>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="banner-text" data-aos="fade-up" data-aos-duration="2000">
                                    <h1>Entrust your smile to professionals</h1>
                                    <h2>6th Lane Dentistry <br/>By Dr Hiran Thenuwara</h2>
                                    
                                    <p>Your Trusted State-of-the-Art Center for Affordable, High-Quality Care and Green Dentistry !</p>


                                    <div className="banner-bottom">

                                    <div className="row contact-info">
        <div className="col-12 mb-3 d-flex align-items-center">
          <i className="bi bi-telephone icon-telephone"></i>
          <span>
            <a href="tel:+94777612834">+94-777-612-834</a>
          </span>
        </div>
        <div className="col-12 mb-3 d-flex align-items-center">
          <i className="bi bi-chat icon-chat"></i>
          <span>
            <a href="tel:+94776663100">+94-77-666-3100</a>
          </span>
        </div>
        <div className="col-12 mb-3 d-flex align-items-center">
          <i className="bi bi-envelope icon-envelope"></i>
          <span>
            <a href="mailto:6thlanedentistry@gmail.com">6thlanedentistry@gmail.com</a>
          </span>
        </div>
        <div className="col-12 mb-3 d-flex align-items-center">
          <i className="bi bi-globe icon-globe"></i>
          <span>
            <a href="http://www.6thlanedentistry.com" target="_blank" rel="noopener noreferrer">
              www.6thlanedentistry.com
            </a>
          </span>
        </div>
        <div className="col-12 mb-3 d-flex align-items-center">
          <i className="bi bi-geo-alt icon-geo"></i>
          <span>
            <a target='_blank' href='https://maps.app.goo.gl/9HWtMybsjorLRvCZA' rel="noreferrer">
            19, Sunethradevi Road (Colombo - Horana main road), Kohuwala, Nugegoda, Colombo, Sri Lanka

            </a><br/>
          </span>
        </div>
      </div>
      





                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="banner-img-area" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="banner-img">
                                        <img src={bannerImg} alt="banner model" />
                                    </div>



                                    {/* Vector Shapes */}
                                    <div className="shapes">
                                        <img src={shapeOne} alt="shape" />
                                        <img src={shapeTwo} alt="shape" />
                                        <img src={shapeThree} alt="shape" />
                                        {/* <img src={shapeFour} alt="shape" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Banner Pattern Vector*/}
            {/* <img className='banner-pattern' src={bannerPattern} alt="banner pattern" /> */}
        </section>
    );
};

export default Banner;